import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import { getDate } from "../../helpers/DateHelper";

const EditEventForm = () => {
  const [eventFor, setEventFor] = useState("");

  const params = useParams();
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [branches, setBranches] = useState([]);
  const [grades, setGrades] = useState([]);
  const [description, setDescription] = useState("");
  const [isHoliday, setIsHoliday] = useState(false);
  const [session, setSession] = useState("");
  const [screenData, setScreenData] = useState({
    grades: [],
    branches: [],
    sessions: [],
  });

  console.log(session);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/eventAddScreen`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { branches, grades, sessions } = response.data;
        setScreenData({
          ...screenData,
          grades,
          branches,
          sessions,
        });
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/event/${params.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { event } = response.data;
console.log("response.data:",event)
        setEventName(event[0].name);
        
        const date = new Date(event[0].date);

        // Get the year, month, and day components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Create the formatted date string in "yyyy-MM-dd" format
        const formattedDate = `${year}-${month}-${day}`;
        //
        console.log("formattedDate:", formattedDate);

        // for dateTo

        const dateTo = new Date(event[0].dateTo);

        // Get the year, month, and day components
        const yearTo = dateTo.getFullYear();
        const monthTo = String(dateTo.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const dayTo = String(dateTo.getDate()).padStart(2, "0");

        // Create the formatted date string in "yyyy-MM-dd" format
        const formattedDateTo = `${yearTo}-${monthTo}-${dayTo}`;

        setDate(formattedDate);
        setDateTo(formattedDateTo);

        setBranches(event[0].branches);
        if (event.for_all_branches) {
          setBranches(["all_branches"]);
        }
        setGrades(event[0].grades);
        setDescription(event[0].description);
        setEventFor(event[0].eventFor);
        setSession(event[0].session);
        setIsHoliday(event[0].isHoliday);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: params.id,
      name: eventName,
      date,
      dateTo,
      branches: branches.includes("all_branches") ? [] : branches,
      grades: grades.includes("all_grades") ? [] : grades,
      description,
      for_all_branches: branches.includes("all_branches") ? true : false,
      for_all_grades: grades.includes("all_grades") ? true : false,
      eventFor: eventFor,
      isHoliday,
      session,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/event/edit`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Event Updated Successfully",
            type: "success",
          });
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  const handleBranchesChange = (e) => {
    const options = e.target.options;
    const selectedBranches = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedBranches.push(options[i].value);
      }
    }
    setBranches(selectedBranches);
  };

  const handleGradesChange = (e) => {
    const options = e.target.options;
    const selectedGrades = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedGrades.push(options[i].value);
      }
    }
    setGrades(selectedGrades);
  };
  const handleEventForChange = (e) => {
    setEventFor(e.target.value);
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h4 className="card-title">Edit information</h4>
            <div className="col-xl-2 px-3 px-xl-1 m-0"></div>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className=" row mb-4">
                <label className="col-md-3 form-label ">
                  Event Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event Name"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label" htmlFor="example-email">
                Session<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="session"
                    value={session}
                    onChange={(e) => {
                      console.log("Selected value:", e.target.value);
                      setSession(e.target.value);
                    }}
                    required
                  >
                    <option value="" selected>
                      Select Session
                    </option>
                    {screenData.sessions.map((session) => (
                      <option value={session.session_name}>{session.session_name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Event From Date<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    // min={ new Date().toISOString().split('T')[0] }
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Event To Date <span className="text-danger">*</span></label>
                <div className="col-md-9">
                  <input
                    type="date"
                    className="form-control"
                    id="date1"
                    // min={ new Date().toISOString().split('T')[0] }
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Branches<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    multiple
                    className="form-control"
                    id="branches"
                    value={branches}
                    onChange={handleBranchesChange}
                    required
                  >
                    <option value="all_branches" selected>
                      All Branches
                    </option>
                    {screenData.branches.map((branch) => (
                      <option value={branch._id}>{branch.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                Grades<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    multiple
                    className="form-control"
                    id="grades"
                    value={grades}
                    onChange={handleGradesChange}
                    required
                  >
                    <option value="all_grades" selected>
                      All Grades
                    </option>
                    {screenData.grades.map((grade) => (
                      <option value={grade._id}>{grade.name}</option>
                    ))}
                  </select>
                </div>  
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Event For<span className="text-danger">*</span>
                </label>
                <div class="material-switch col-md-9">
                  <select
                    className="form-control"
                    id="eventFor"
                    value={eventFor}
                    onChange={handleEventForChange}
                  required
                    
                  >
                    <option value="">Select Event For</option>
                    <option value="T">Teachers</option>
                    <option value="S">Students</option>
                    <option value="TS">Teachers&Students</option>
                  </select>
                </div>  
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                Is Holiday
                </label>
                <div class="material-switch col-md-9">
                  <input
                    id="form_fee"
                    name="form_fee"
                    type="checkbox"
                    checked={isHoliday}
                    onChange={(e) => {
                      setIsHoliday(e.target.checked)
                    }}              
                  />
                  <label for="form_fee" class="label-success"></label>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Description</label>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
              Update Event
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );

};
export default EditEventForm;
